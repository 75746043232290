<template>
  <div class="headers">
    <div class="nav">
      <img src="@/assets/images/logo.png" alt=""/>
      <el-menu
          :default-active="$route.path"
          router
          class="el-menu-demo"
          mode="horizontal"
          text-color="#494848"
          active-text-color="#AF1E24"
      >
        <el-menu-item index="/home">首页</el-menu-item>
        <el-menu-item index="/company">公司概况</el-menu-item>
        <el-menu-item index="/business">主营业务</el-menu-item>
        <el-menu-item index="/news">新闻资讯</el-menu-item>
        <el-menu-item v-show="downList.length>0" index="/down">下载</el-menu-item>
        <el-menu-item index=""><a href="http://open.hanglvfeike.com/" target="_blank">开放平台</a></el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Headers",
  data(){
    return{
      downList:[],
    }
  },
  created() {
    this.getInfo()
  },
  methods:{
    getInfo(){
      axios.get('https://crsapi.hanglvfeike.com/base/notifList?type=99&owner=8023').then(res=>{
        this.downList = res.data.result
      })
    },
  },
}
</script>

<style lang="less" scoped>
.headers{
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  //background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .nav{
    box-sizing: border-box;
    width: 1200px;
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      height: 36px;
    }
  }
  /deep/.el-menu{
    background-color: rgba(255,255,255,0);
  }
  /deep/.el-menu-item{
    padding: 0!important;
    margin: 0 20px!important;
    background-color: rgba(255,255,255,0);
  }
  /deep/.el-menu--horizontal>.el-menu-item{
    height: 36px;
    line-height: 36px;
  }
  /deep/.el-menu.el-menu--horizontal{
    border: none!important;
  }
  /deep/.el-menu--horizontal>.el-menu-item.is-active{
    background-color: rgba(255,255,255,0) !important;
  }
  /deep/.el-menu--horizontal>.el-menu-item:hover{
    color: #AF1E24!important;
    background-color: rgba(255,255,255,0) !important;
  }
  a{
    color: #494848;
    text-decoration: none;
  }
}
</style>
