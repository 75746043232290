import axios from 'axios'
import JSONBig from 'json-bigint';
import {
  Notification,
  MessageBox,
  Message
} from 'element-ui'
import store from '@/store'
import {
  getToken
} from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import {Md5} from '@/utils/open_encrypt_util'
import * as qs from "qs";
import da from "element-ui/src/locale/lang/da";
import el from "element-ui/src/locale/lang/el";

// `transformResponse` 在传递给 then/catch 前，允许修改响应数据---使前端支持超长Long型数据，将Long转为了字符串
axios.defaults.transformResponse = [
  function (data) {
    const json = JSONBig({
      storeAsString: true
    })
    const res = json.parse(data)
    return res
  }
]
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,

  // 超时
  timeout: 30000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  //这里填入正确的secretKey，这里我只是阐述加密规则，具体问卿经理密钥 mark by Gohn.
  /**
   * 签名计算：首先转换签名明文(P)为UTF-8的字节数组（byte[]）,
   * 通过标准摘要算法计算出摘要值的字节数组形式（byte[]），
   * 然后转换摘要的字节数组为Hex（小写字母的16进制形式）的字符串格式(S)则为摘要签名。
   * 伪代码公式为: S=Hex(MD5(P.getBytes(“UTF-8”)))
   * */
  const obj = require('../../public/data.json');
  const secretKey = obj.secretKey
  // 这里构建body 无需特殊判定
  if (config.params) {
    let dict = dictSortByKey(config.params);
    let url = config.url + '?';
    //构建待签名参数字符
    let waitSignStr = '';
    for (const propName of Object.keys(dict)) {
      if (propName !== 'sign') {
        const value = dict[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
          if (typeof value === 'object') {
            url += part + JSON.stringify(value) + "&";
            waitSignStr += part + JSON.stringify(value) + "&";
          } else {
            url += part + encodeURIComponent(value) + "&";
            waitSignStr += part + encodeURIComponent(value) + "&";
          }
        }
      }
    }
    //生成签名参数
    let signStr = Md5(waitSignStr.slice(0, -1) + secretKey, 'md5', 'hex')
    url = encodeURI(url.slice(0, -1)) + "&sign=" + signStr;
    config.url = url;
  }
  if (config.data) {
    let dict = dictSortByKey(config.data);
    let data = '';
    //构建待签名参数字符
    let waitSignStr = '';
    for (const propName of Object.keys(dict)) {
      const value = dict[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof (value) !== "undefined") {
        if (typeof value === 'object') {
          data += part + JSON.stringify(value) + "&";
          waitSignStr += part + JSON.stringify(value) + "&";
        } else {
          data += part + encodeURIComponent(value) + "&";
          waitSignStr += part + encodeURIComponent(value) + "&";
        }
      }
    }
    //生成签名参数
    let signStr = Md5(waitSignStr.slice(0, -1) + secretKey, 'md5', 'hex')
    // "sign=" + 'hqsxr666'
    data = encodeURI(data.slice(0, -1)) + "&sign=" + signStr;
    config.data = data;
  }
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 提供一个排序方法 Gohn GM.
function dictSortByKey(dict){
  let newKeys = Object.keys(dict).sort();

  let newDict = {};
  for(var i = 0; i < newKeys.length; i++){
    newDict[newKeys[i]] = dict[newKeys[i]];
  }

  return newDict;
}

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = errorCode[code] || res.data.resultDetail || res.data.resultMessage || errorCode['default']
  if (code === 401) {
    MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      store.dispatch('LogOut').then(() => {
        location.href = '/index';
      })
    }).catch(() => { });
    return Promise.reject()
  } else if (code === 500) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    Notification.error({
      title: msg
    })
    return Promise.reject('error')
  } else {
    if(res.data.resultMessage === '导出成功，请前往下载中心下载。'){
      MessageBox.confirm(msg, '', {
        confirmButtonText: '去下载中心',
        cancelButtonText: '关闭',
        type: 'success'
      }).then(() => {
        location.href = '/help';
      }).catch(() => { });
    }else if (res.data.resultDetail === '登录失效，请重新登录') {
      store.dispatch('LogOut').then(() => {
        location.href = '/index';
      })
      Message({
        message: msg,
        type: 'error'
      })
    }else if (!res.data.success) {
      Message({
        message: msg,
        type: 'error'
      })
    }
    return res.data
  }
},
  error => {
    console.log('err' + error)
    let {
      message
    } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
