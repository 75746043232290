import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Layout from '@/views/Layout.vue'

Vue.use(VueRouter)

const routes = [
  //咨询信息表单
  {
    path: '/apply',
    meta:{title:'咨询信息表单'},
    component: ()=>import('@/views/apply'),
  },
  //下载
  {
    path: '/down',
    meta:{title:'下载'},
    component: ()=>import('@/views/down'),
  },
  //首页
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: Layout,
    children:[
      {
        path:'/home',
        meta:{title:'首页'},
        component:Home
      }
    ]
  },
  //公司概况
  {
    path:'',
    component: Layout,
    children:[
      {
        path:'/company',
        meta:{title:'公司概况'},
        component:()=>import('@/views/company')
      }
    ]
  },
  //主营业务
  {
    path:'',
    component: Layout,
    children:[
      {
        path:'/business',
        meta:{title:'主营业务'},
        component:()=>import('@/views/business')
      }
    ]
  },
  //新闻资讯
  {
    path:'',
    component: Layout,
    children:[
      {
        path:'/news',
        meta:{title:'新闻资讯'},
        component:()=>import('@/views/news')
      }
    ]
  },
  //开放平台
  {
    path:'',
    component: Layout,
    children:[
      {
        path:'/open_platform',
        meta:{title:'开放平台'},
        component:()=>import('@/views/open/open_platform')
      }
    ]
  },
]

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
