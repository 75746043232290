<template>
  <div class="back_top" @click="backTop" v-show="isShow">
    <img src="@/assets/images/top.png" alt=""/>
  </div>
</template>

<script>
export default {
  name: "BackTop",
  data(){
    return{
      isShow:false
    }
  },
  created() {
  },
  mounted() {
    window.addEventListener("scroll", this.showIcon);
  },
  methods:{
    backTop(){
      document.documentElement.scrollTop = 0;
    },
    showIcon() {
      if (
          !!document.documentElement.scrollTop &&
          document.documentElement.scrollTop > 1200
      ) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },
  }
}
</script>

<style lang="less" scoped>
.back_top{
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.32);
  position: fixed;
  right: 32px;
  bottom: 420px;
  cursor: pointer;
  img{
    box-sizing: border-box;
    width: 40px;
    height: 40px;
  }
}
</style>
