<template>
  <div class="home">
    <el-carousel indicator-position="none" height="600px">
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <img style="width: 100%" :src="item.path" alt=""/>
      </el-carousel-item>
    </el-carousel>

    <div class="main">
      <div class="one-box">
        <div class="item">
          <div class="title">丰富资源</div>
          <p>机票 覆盖全球上百家航司
            酒店 全球220万+酒店资源
            用车 一键叫车 多种用车场景
            火车 覆盖全国车次线路</p>
        </div>
        <div class="item">
          <div class="title">一站式服务</div>
          <p>差旅管控 全流程差旅智能化管控
            对公结算 企业对公结算减轻压力
            告别垫资 员工出差免垫资免贴票
            贴心服务 7*24小时全天候服务</p>
        </div>
        <div class="item">
          <div class="title">系统定制搭建方案</div>
          <p>根据不同企业的差旅需求，提供差旅管理系统、H5定制、OA/ERP系统对接，实现差旅管理的一体化管理</p>
        </div>
      </div>

      <!-- 产品与服务 -->
      <div class="flex-sb title-row">
        <div class="h2">产品与服务</div>
        <div class="h2_desc">Products and services</div>
      </div>
      <div class="pro-box">
        <div class="left">
          <div class="two-item">
            <img src="@/assets/images/pro.png" alt=""/>
            <div class="tit">产品解决方案</div>
            <div class="text">
              <p>整合产品资源</p>
              <p>提供机票、火车票、酒店、用车等产品</p>
              <p>满足企业用户不同里程的旅途需求</p>
              <p>每个产品向用户提供优质和满意的服务</p>
            </div>
            <div class="more">
              <span @click="goBusin('a')">
                查看更多
                <i class="el-icon-right"/>
              </span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="two-item">
            <img src="@/assets/images/pro1.png" alt=""/>
            <div class="tit">服务解决方案</div>
            <div class="text">
              <p>为企业用户提供员工差旅流程管理、差旅预订、差旅报销、差旅分</p>
              <p>析报表等企业差旅管理综合解决方案，可根据各个行业、不同人员</p>
              <p>规模企业的差旅管理需求进行专属定制。</p>
            </div>
            <div class="more">
              <span @click="goBusin('b')">
                查看更多
                <i class="el-icon-right"/>
              </span>
            </div>
          </div>
          <div class="two-item">
            <img src="@/assets/images/pro2.png" alt=""/>
            <div class="tit">资金解决方案</div>
            <div class="text">
              <p>支持多种结算方式，灵活便捷，同时提供垫资服务，解决中小企业</p>
              <p>融资难、融资贵的问题</p>
            </div>
            <div class="more">
              <span @click="goBusin('c')">
                查看更多
                <i class="el-icon-right"/>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 视频播放 -->
    <div style="width: 100%;height: 712px">
      <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsline="true" :options="playerOptions"></video-player>
    </div>


    <!-- 企业优势 -->
    <div class="main qy-box">
      <div class="flex-sb title-row">
        <div class="h2">企业优势</div>
        <div class="h2_desc">Enterprise advantages</div>
      </div>
      <div class="bus-box">
        <div class="bus-item">
          <img src="@/assets/images/enterprise.png" alt=""/>
          <div class="tit">自主知识产权</div>
          <div class="text">拥有自主知识产权的差旅管理解决方案;拥有完整的差旅技术解决能力;行业内效率最高的系统对接能力;</div>
        </div>
        <div class="bus-item">
          <img src="@/assets/images/enterprise1.png" alt=""/>
          <div class="tit">优化企业管理</div>
          <div class="text">OA、ERP直连，提高管理效率;
            提供月结无需员工垫资、集中采购，大客户协议统一管理;</div>
        </div>
        <div class="bus-item">
          <img src="@/assets/images/enterprise2.png" alt=""/>
          <div class="tit">产品覆盖最全</div>
          <div class="text">产品是国内覆盖最全的差旅管理公司之一;
            全球300多家的航空公司合作;
            协议酒店已超过1万家
            与国内45万+国外50万+酒店对接;</div>
        </div>
      </div>
    </div>

    <!-- 新闻资讯 -->
    <div class="news-boxs">
      <div class="main">
        <div class="flex-sb title-row">
          <div class="h2">新闻资讯</div>
          <div class="h2_desc">News and information</div>
        </div>
        <div class="news-box">
<!--          <div class="news-item" v-for="item in newslist" @mouseover="item.flag=true" @mouseout="item.flag=false">-->
          <div class="news-item" v-for="item in newslist"  @click="goNews(item)">
            <img :src="item.headimg" alt=""/>
            <div class="tit-box">{{ item.title }}</div>
<!--            <div class="item-desc" v-show="item.flag">-->
<!--              <div class="content">-->
<!--                <div class="tit">{{ item.title }}</div>-->
<!--                <div class="desc">-->
<!--                  <div class="text-line-clamp" v-html="item.content"></div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="detail" @click="goNews(item)">点击查看详情<i class="el-icon-right"/></div>-->
<!--            </div>-->
          </div>
        </div>
        <div class="news-more" @click="$router.push('/news')">
          查看更多
          <i class="el-icon-right"/>
        </div>
      </div>
    </div>

    <!-- 合作企业 -->
    <div class="main">
      <div class="flex-sb title-row">
        <div class="h2">合作企业</div>
        <div class="h2_desc">Cooperative enterprise</div>
      </div>
      <div class="brand-box">
        <div class="brand-item" v-for="item in brandList">
          <img v-if="brandList.length>0" :src="item.logo" alt=""/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {test_api} from "@/apis";

export default {
  name: "Home",
  data(){
    return{
      bannerList:[],
      videolist:[],
      newslist:[],
      brandList:[],
      videoUrl:'',
      flag:false,
      reply:[],
      playerArr: [],
      playerOptions: {},
    }
  },
  created() {
    document.documentElement.scrollTop = 1;
    this.getInfo()
    // this.getPro()
    this.getVideo()
    this.getNews()
    this.getBrand()
  },

  watch: {
    $route: {
      handler(newVal, oldVal) {
        if(newVal !== oldVal){
          let selector = this.$route.query.selector?this.$route.query.selector:'top'
          if(selector === 'top'){
            document.documentElement.scrollTop = 0;
          }else if(selector === 'proSer'){
            document.documentElement.scrollTop = 800;
          }else if(selector === 'enAd'){
            document.documentElement.scrollTop = 2500;
          }else if(selector === 'news'){
            document.documentElement.scrollTop = 3001;
          }else if(selector === 'cooper'){
            document.documentElement.scrollTop = 3500;
          }
        }
      },
      immediate: true,
      deep: true
    },
  },
  mounted() {
    window.addEventListener("scroll", this.showIcon);
  },
  methods:{
    getVideoData(){
      for(let i of this.reply){
        let arrStr = {
          playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
          autoplay: false, // 如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: i.src // url地址
          }],
          poster: '', // 你的封面地址
          // width: document.documentElement.clientWidth, // 播放器宽度
          height:712,
          notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true // 全屏按钮
          }
        }
        this.playerArr.push(arrStr)
        if(this.playerArr.length>0){
          this.playerOptions = this.playerArr[0]
        }
      }
    },
    showIcon() {
      // console.log(document.documentElement.scrollTop)
      let selector = this.$route.query.selector?this.$route.query.selector:'top'
      if(selector === 'top'){
        document.documentElement.scrollTop = 0;
      }else if(selector === 'proSer'){
        document.documentElement.scrollTop = 800;
      }else if(selector === 'enAd'){
        document.documentElement.scrollTop = 2500;
      }else if(selector === 'news'){
        document.documentElement.scrollTop = 3001;
      }else if(selector === 'cooper'){
        document.documentElement.scrollTop = 3500;
      }
      window.removeEventListener('scroll',this.showIcon);
    },
    goBusin(val){
      this.$router.push({
        path:'/business',
        query:{
          selector:val,
        }
      })
    },
    goNews(info){
      sessionStorage.setItem('newsInfo',JSON.stringify(info))
      this.$router.push({
        path:'/news',
      })
    },
    //banner
    getInfo(){
      this.bannerList = []
      const service = 'website_bannerlist_web_svr'
      const requestData = {
        curPage:1,
      }
      test_api(service,requestData).then(res=>{
        if(res.success){
          if(res.resultData.list.length>0){
            res.resultData.list.forEach(item=>{
              if(item.enabled == 1){
                this.bannerList.push(item)
              }
            })
          }
        }
      })
    },
    //产品解决方案
    getPro(){
      const service = 'website_productlist_web_svr'
      const requestData = {
        type:1,
        curPage:1,
      }
      test_api(service,requestData).then(res=>{
        if(res.success){
          this.prolist = res.resultData.list
        }
      })
    },
    //视频
    getVideo(){
      const service = 'website_videolist_web_svr'
      const requestData = {
        curPage:1,
      }
      test_api(service,requestData).then(res=>{
        if(res.success){
          this.videolist = res.resultData.list
          if(this.videolist.length>0){
            this.videolist.forEach(item=>{
              if(item.enabled == 1){
                this.reply.push({
                  src:item.path
                })
              }
            })
          }
          this.getVideoData()
        }
      })
    },
    //新闻
    getNews(){
      const service = 'website_newslist_web_svr'
      const requestData = {
        curPage:1,
      }
      test_api(service,requestData).then(res=>{
        if(res.success){
          if(res.resultData.list.length>0){
            res.resultData.list.forEach(item=>{
              item.flag = false
            })
          }
          let newslist = res.resultData.list
          if(newslist.length>3){
            this.newslist = newslist.slice(0,3)
          }else {
            this.newslist = newslist
          }
        }
      })
    },
    //合作品牌
    getBrand(){
      const service = 'website_brandlist_web_svr'
      const requestData = {
        curPage:1,
      }
      test_api(service,requestData).then(res=>{
        if(res.success){
          this.brandList = res.resultData.list
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.home{
  .flex{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .flex-sb{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-carousel{
    height: 600px!important;
  }
  .main{
    box-sizing: border-box;
    width: 1200px;
    margin: 0 auto;
    .h2{
      font-size: 24px;
      color: #494848;
      font-weight: bold;
    }
    .h2_desc{
      font-size: 22px;
      color: #b6b4b4;
      font-weight: 200;
    }
    .one-box{
      box-sizing: border-box;
      width: 1200px;
      height: 232px;
      padding: 1.8rem 0;
      font-size: 14px;
      color: #6A6565;
      display: flex;
      justify-content: space-around;
      background: #FFF;
      box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1000);
      border-radius: 8px;
      position: relative;
      top: -78px;
      z-index: 4;
      .item{
        box-sizing: border-box;
        width: 206px;
        text-align: left;
        .title{
          font-size: 20px;
          color: #494848;
          font-weight: bold;
          margin-bottom: 1.5rem;
        }
        p{
          line-height: 1.5rem;
        }
      }
    }
    .title-row{
      margin: 3.2rem 0 2.2rem 0;
    }
    .pro-box{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .two-item{
        text-align: left;
        margin-bottom: 2rem;
        .tit{
          font-size: 20px;
          color: #494848;
          margin: 2rem 0;
        }
        .text{
          font-size: 14px;
          color: #6A6565;
          line-height: 1.45rem;
        }
        .more{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          span{
            box-sizing: border-box;
            padding: 10px;
            font-size: 14px;
            color: #B9383E;
            cursor:pointer;
          }
          span:hover{
            background-color: #F3F3F3;
          }
        }
      }
      .left{
        img{
          width: 500px;
        }
      }
      .right{
        img{
          width: 625px;
        }
      }
    }
    .bus-box{
      box-sizing: border-box;
      padding: 1.8rem 0;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      .bus-item{
        box-sizing: border-box;
        width: 276px;
        text-align: center;
        img{
          width: 80px;
          height: 80px;
        }
        .tit{
          font-size: 20px;
          color: #494848;
          font-weight: bold;
          margin: 2rem 0;
        }
        .text{
          font-size: 14px;
          color: #6A6565;
          line-height: 1.45rem;
        }
      }
    }
    .news-box{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .news-item{
        box-sizing: border-box;
        width: 390px;
        height: 420px;
        border-radius: 8px;
        margin-right: .8rem;
        position: relative;
        img{
          box-sizing: border-box;
          width: 390px;
          height: 420px;
          border-radius: 8px;
        }
        .tit-box{
          box-sizing: border-box;
          width: 390px;
          height: 84px;
          line-height: 84px;
          font-size: 24px;
          font-weight: 400;
          color: #ECECEC;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          background-color: rgba(63, 56, 56, .5);
          border-radius: 0 0 8px 8px;
          cursor: pointer;
          position: absolute;
          bottom: 0;
        }
        .item-desc{
          box-sizing: border-box;
          position: absolute;
          top: 0;
          width: 390px;
          height: 420px;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 400;
          color: #ECECEC;
          .content{
            box-sizing: border-box;
            width: 390px;
            height: 336px;
            padding-top: 2.8rem;
            border-radius: 8px 8px 0 0;
            background-color: rgba(63, 56, 56, .5);
            .tit,.desc{
              text-align: left;
              padding: 0 1.2rem;
            }
            .tit{
              font-size: 24px;
            }
            .desc{
              box-sizing: border-box;
              padding-bottom: 2.2rem;
              .text-line-clamp{
                margin-top: 2rem;
                line-height: 1.5rem;
                box-sizing: border-box;
                overflow-wrap: break-word;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
              }
            }
          }
          .detail{
            box-sizing: border-box;
            width: 390px;
            height: 84px;
            line-height: 84px;
            font-size: 14px;
            font-weight: 400;
            color: #ECECEC;
            text-align: center;
            background-color: #AF1E24;
            border-radius: 0 0 8px 8px;
            cursor: pointer;
          }
        }
      }
    }
    .news-more{
      box-sizing: border-box;
      width: 94px;
      height: 34px;
      font-size: 14px;
      color: #fff;
      background: #AF1E24;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      display: -webkit-flex;
      -webkit-justify-content: center;
      -webkit-align-items: center;
      cursor:pointer;
      margin: 2.2rem 0 6.5rem 0;
    }
    .news-more:hover{
      background-color: #f78989;
    }
    .brand-box{
      box-sizing: border-box;
      width: 1200px;
      padding: 2rem 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .brand-item{
        box-sizing: border-box;
        width: 110px;
        margin: 0 .8rem 2.2rem 0;
        img{
          box-sizing: border-box;
          width: 110px;
        }
      }
    }
  }
  .qy-box{
    box-sizing: border-box;
    padding-top: 3rem;
  }
  .news-boxs{
    background-color: #F3F3F3;
    padding: 2.5rem 0;
    margin-top: 1.8rem;
    .title-row{
      margin-bottom: 4.5rem;
    }
  }
  /deep/.vjs-custom-skin {
    box-sizing: border-box;
    width: 1200px;
    height: 712px;
    margin: 2.2rem auto;
  }
  /deep/.video-js.vjs-fluid:not(.vjs-audio-only-mode){
    box-sizing: border-box;
    height: 712px;
  }
  /deep/.video-js.vjs-fluid{
    width: auto;
  }
  /deep/.vjs_video_536-dimensions.vjs-fluid{
    padding-top: 0;
  }
  /deep/.video-js{
    box-sizing: border-box;
    width: 1200px;
    height: 712px;
    background-color: #fff;
    text-align: center;
  }
  /deep/.video-js .vjs-tech{
    box-sizing: border-box;
    width: 1200px;
    height: 712px;
  }
  /deep/#vjs_video_536_html5_api{
    width: 100%;
    height: 100%;
  }
}
</style>
