import request from '@/utils/request'
import {randomNum} from "@/utils/random";

// 接口api
export function test_api(service,requestData) {
  const data = {
    requestNo:randomNum(16),
    service:service,
    partnerId:'22021016172215600000',
    signType:'MD5',
    requestData:requestData,
  }
  return request({
    url: '/api/test',
    method: 'post',
    data: data
  })
}

