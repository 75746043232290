import {test_api} from '@/apis'
import {getToken, setToken, removeToken} from '@/utils/auth'
import Cookies from 'js-cookie'
// 引入js
import hex_md5 from '@/utils/md5.js';
import {randomNum} from "@/utils/random";


const user = {
    state: {
        token: getToken(),
        staffId: '',
        nickname: '',
        phone: '',
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_STAFF_ID: (state, staffId) => {
            state.staffId = staffId
        },
        SET_NICKNAME: (state, nickname) => {
            state.nickname = nickname
        },
        SET_PHONE: (state, phone) => {
            state.phone = phone
        },
    },

    actions: {
        // 登录
        Login({commit}, userInfo) {
          const service = 'customer_login_svr'
          const username = userInfo.username.trim()
          const password = userInfo.password
          const requestData = {
            username,
            password
          }
          return new Promise((resolve, reject) => {
              test_api(service,requestData).then(res => {
                if(res.success){
                  setToken(res.resultData.token)
                  commit('SET_TOKEN', res.resultData.token)
                  localStorage.setItem('nickName', res.resultData.nickName)
                  localStorage.setItem('phone', res.resultData.phone)
                  Cookies.set('loginFlag',res.resultData.extend1,{ expires: 30 })
                }
                resolve()
              }).catch(error => {
                  reject(error)
              })
          })
        },


        // 退出系统
        LogOut({commit, state}) {
          const service = 'customer_logout_svr'
          const requestData = {
            token:getToken()
          }
          return new Promise((resolve, reject) => {
            test_api(service,requestData).then(()=>{
              commit('SET_TOKEN', '')
              removeToken()
              localStorage.clear()
              resolve()
            })
          })
        },
    }
}

export default user
