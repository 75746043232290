<template>
  <div class="footers">
    <div class="main">
      <div class="top-box">
        <div class="left"><img src="@/assets/images/logos.png" alt=""></div>
        <div class="content-box">
          <div class="item">
            <div class="title" @click="goLink('/home','top')">首页</div>
            <p @click="goLink('/home','proSer')">产品与服务</p>
            <p @click="goLink('/home','enAd')">企业优势</p>
            <p @click="goLink('/home','news')">新闻资讯</p>
            <p @click="goLink('/home','cooper')">合作企业</p>
          </div>
          <div class="item">
            <div class="title" @click="goLink('/company','top')">公司概况</div>
            <p @click="goLink('/company','synopsis')">企业简介</p>
            <p @click="goLink('/company','history')">发展历程</p>
          </div>
          <div class="item">
            <div class="title" @click="goLink('/business','a')">主营业务</div>
            <p @click="goLink('/business','a')">产品解决方案</p>
            <p @click="goLink('/business','b')">服务解决方案</p>
            <p @click="goLink('/business','c')">资金解决方案</p>
          </div>
          <div class="item">
            <div class="title" @click="goLink('/news','')">新闻资讯</div>
            <p @click="goLink('/news',1)">企业新闻</p>
            <p @click="goLink('/news',2)">行业动态</p>
          </div>
          <div class="item">
            <div class="title"><a href="http://open.hanglvfeike.com/" target="_blank">开放平台</a></div>
          </div>
        </div>
        <div class="right-box">
          <div class="title">联系我们</div>
          <div class="row">
            <div class="label">资讯热线：</div>
            <div>{{ info.tel }}</div>
          </div>
          <div class="row">
            <div class="label">资讯邮箱：</div>
            <div>{{ info.email }}</div>
          </div>
          <div class="row">
            <div class="label">地址：</div>
            <div>{{ info.address }}</div>
          </div>
          <div class="row">
            <div class="label">官网：</div>
            <div>{{ info.web }}</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div>四川航旅飞客航空科技有限公司</div>
        <div>
          版权所有©
          <a style="text-decoration: none;color: #fff"
             href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">蜀ICP备2020034426号-2</a>
          隐私条款
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {test_api} from "@/apis";

export default {
  name: "Footers",
  data(){
    return{
      info:{},
    }
  },
  created() {
    this.getInfo()
  },
  methods:{
    //联系地址
    getInfo(){
      const service = 'website_companylist_web_svr'
      const requestData = {}
      test_api(service,requestData).then(res=>{
        if(res.success){
          this.info = res.resultData
        }
      })
    },
    goLink(path,selector){
      this.$router.push({
        path:path,
        query:{
          selector
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.footers{
  box-sizing: border-box;
  width: 100%;
  height: 366px;
  background-color: #AF1E24;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  .main{
    box-sizing: border-box;
    width: 1200px;
    height: 366px;
    padding-top: 2.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top-box{
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .left{
        box-sizing: border-box;
        width: 108px;
        height: 36px;
        img{
          box-sizing: border-box;
          height: 36px;
        }
      }
      .content-box{
        box-sizing: border-box;
        display: flex;
        .item{
          text-align: left;
          margin-left: 1.8rem;
          .title{
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 2.5rem;
            cursor: pointer;
            a{
              color: #fff;
              text-decoration: none;
            }
          }
          p{
            margin-bottom: 1rem;
            cursor: pointer;
          }
        }
      }
      .right-box{
        box-sizing: border-box;
        width: 330px;
        text-align: right;
        .title{
          box-sizing: border-box;
          width: 330px;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 2.2rem;
        }
        .row{
          box-sizing: border-box;
          width: 330px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          display: -webkit-flex;
          -webkit-justify-content: flex-end;
          -webkit-align-items: center;
          margin-bottom: 1rem;
          .label{
            width: 78px!important;
          }
        }
      }
    }
    .bottom{
      box-sizing: border-box;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid rgba(255,255,255,.3);
    }
  }
}
</style>
