import router from './router'
import store from './store'
import { getToken } from '@/utils/auth'

const whiteList = [ '/', '/home', '/company', '/business', '/news', '/apply', '/open_platform', '/down']

router.beforeEach((to, from, next) => {
  if (getToken()) {
    if (to.path === '/') {
      next({ path: '/home' })
    }else {
      next()
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/`) // 否则全部重定向到登录页
    }
  }
  document.title = to.meta.title
})

router.afterEach(() => {
})
