import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/resset.css';
import Headers from "@/components/Headers"
import Footers from "@/components/Footers"
import BackTop from "@/components/BackTop";
import './permission'
import clipboard from 'clipboard';

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'

import Video from 'video.js';
import 'video.js/dist/video-js.css';

Vue.prototype.$video = Video;

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.clipboard = clipboard
Vue.component('headers',Headers)
Vue.component('footers',Footers)
Vue.component('back-top',BackTop)

Vue.use(ElementUI);
Vue.use(VideoPlayer)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
