<template>
  <div class="layout">
    <!-- 上边一级菜单部分 -->
    <div class="top-menu" :class="$route.path==='/home'?'header_position':($route.path==='/news'?'header_position':'')">
      <headers/>
    </div>
    <!-- 页面内容部分 -->
    <div class="bottom">
      <router-view></router-view>
    </div>
    <!-- 页脚部分 -->
    <div class="footers-bottom">
      <footers/>
    </div>

    <!-- 返回顶部 -->
    <div class="back-icon">
      <back-top/>
    </div>
  </div>
</template>

<script>
export default {
name: "Layout"
}
</script>

<style lang="less" scoped>
.layout{
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top-menu {
    box-sizing: border-box;
    width: 100%;
    height: 72px;
    background-color:rgba(255,255,255,1);
    //display: flex;
    //justify-content: center;
    //align-items: center;
  }
  .header_position{
    background-color:rgba(255,255,255,.5) !important;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3;
  }

  .bottom {
    box-sizing: border-box;
    flex: 1;
    //overflow-y: scroll;
  }
  .bottom::-webkit-scrollbar {
    display: none;
  }

  .footers-bottom{
    box-sizing: border-box;
    width: 100%;
    height: 366px;
    background-color: #AF1E24;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
}
.layout::-webkit-scrollbar {
  display: none;
}
</style>
