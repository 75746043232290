// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "#App_app_3cl75{box-sizing:border-box;width:100%;height:100%}", "",{"version":3,"sources":["D:/Pro/GohnStudio-quickframework-website/fkgw-website/src/D:/Pro/GohnStudio-quickframework-website/fkgw-website/src/D:/Pro/GohnStudio-quickframework-website/fkgw-website/src/App.vue?vue&type=style&index=0&id=a7b4312c&prod&lang=less&module=true&","D:/Pro/GohnStudio-quickframework-website/fkgw-website/src/D:/Pro/GohnStudio-quickframework-website/fkgw-website/src/App.vue"],"names":[],"mappings":"AACA,eACE,qBAAA,CACA,UAAA,CACA,WCAF","file":"App.vue","sourcesContent":["\n#app{\n  box-sizing: border-box;\n  width: 100%;\n  height: 100%;\n}\n","#app {\n  box-sizing: border-box;\n  width: 100%;\n  height: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"app": "App_app_3cl75"
};
module.exports = exports;
